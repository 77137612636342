import React, { Component } from 'react';
import { Link } from "react-router-dom";
import gsap from 'gsap';

class Item extends Component {

    componentDidMount() {

        gsap.fromTo(".category-item", {
            opacity: 0,
            y: function(index, target, targets) { 
              return index * 50;
            },
            rotateY: function(index, target, targets) { 
                return index * 50;
              },
            // x: function(index, target, targets) { 
            //     return index * 50;
            //   }
          },
          {
            opacity: 1,
            y: 0,
            rotateY: 0,
            // x: 0,
            duration: 1
          });
       
    }

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <div>

                <div className="category-item">
                    <Link to={"/menu/" + this.data['id']}>
                        <img src={this.data.cover} alt="" />
                        <h2 style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}> 
                            {
                                this.props.Language == "en" ? this.data['name_eng']
                                : this.props.Language == "krd" ? this.data['name_krd']
                                : this.data['name_ar']
                            }
                        </h2>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Item;