import React, { Component } from 'react'
import Imgloading from './list/itemloading';



export default class Cart extends Component {

    handleCartClose = close => {
        this.props.HandleCartClose(close);
    }

  render() {
    return (
        <div className={`cart${this.props.Show?' cart-show': ''}`}>
        <div className='cart-head ani-rtl-f'>
            <div className='cart-head-close'>
                <i className="fa-solid fa-xmark" onClick={()=> this.handleCartClose(false)}></i>
            </div>
        </div>
        <div className='cart-body'>

                
                <div className='cart-items ani-rtl-f'
                style={ this.props.Language == "en"? {'fontFamily':'Vidaloka'}: {}}
                >({this.props.SumQty.toLocaleString()}) Item(s)</div>
            

            {
                this.props.Cart.length?
                        <>
                            {
                                this.props.Cart.map( (item, index) => {
                                    return <div key={index} className='cart-item ani-stb-f'>
                                                <div className='img'>
                                                    <Imgloading Img={item.info.image} />
                                                </div>
                                                <div
                                                    className={'info' }
                                                    >
                                                    
                                                        
                                                    <h3  style={ this.props.Language == "en"? {'fontFamily':'Vidaloka'}: {}}>
                                                        {
                                                            this.props.Language == "en" ? item.info.name_eng
                                                            : this.props.Language == "krd" ? item.info.name_krd
                                                            : item.info.name_ar
                                                        }
                                                    </h3>
                                                    <span
                                                    style={ this.props.Language == "en"? {'fontFamily':'Vidaloka'}: {}}>
                                                        { item.info.price.toLocaleString() }
                                                        <span className='iqd'>
                                                            IQD
                                                        </span>
                                                        </span>

                                                    <div className='action'>
                                                        
                                                        <i className="fa-solid fa-minus" onClick={() => this.props.updateCartItem(index, '-')}></i>
                                                        <span>{ item.qty.toLocaleString() }</span>
                                                        <i className="fa-solid fa-plus" onClick={() => this.props.updateCartItem(index, '+')}></i>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                })
                            }
                        </>
                        :
                        <p>There are no items in your list.</p>

                    
            }
        </div>

            <div className='cart-fotter'>
                <div className='total-text ani-ltr-f' 
                 style={ this.props.Language == "en"? {'fontFamily':'Vidaloka'}: {}}
                >TOTAL: </div>
                <div className='price-text ani-rtl-f'
                 style={ this.props.Language == "en"? {'fontFamily':'Vidaloka'}: {}}
                >{this.props.SumPrice.toLocaleString()}
                 <span className=''
                style={ this.props.Language == "en"? {'fontFamily':'Vidaloka'}: {}}
                >IQD</span></div>
            </div>

            <div className="footer ani-fade-in" style={ {'fontFamily':'Vidaloka'}}>
                        Developed By <a href='https://www.instagram.com/caviar.menu/' className='caviar'>Caviar Menu</a>
                    </div>
            
    </div>
    )
  }
}