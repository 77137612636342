import React from 'react';
import leaf from '../assets/leaf.png';
import Intro from './intro/intro';
import axios from 'axios';
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Category from './category/category';
import Menu from './menu/menu';
import NotFound from './general/notfound';
import Loader from './general/loader';
import Feedback from './Feedback';
import Daniklogo from '../assets/logo.png';



export default class Main extends React.Component {

    state = {
        settings: [],
        language: "krd",
        load: false,
        cart: [],
        sumPrice: 0,
        sumQty: 0,
    };

    async componentDidMount(){
        const {data} = await axios.get("https://manage.caviarmenu.com/api/info/danik");
        this.setState({ settings: data }, ()=>{
            this.setState({load: true});
        });

        const lang = localStorage.getItem("lang") ?? 'krd';
        if(lang)
            this.setState({language : lang});


    }

    handleLangaugeSubmit = (lang) => {
        this.setState({language : lang});
        localStorage.setItem("lang", lang);
    }


    
    addCartItem = (item) => {        
        const existingItemIndex = this.state.cart.findIndex((i) => i.info.id === item.id);

        if (existingItemIndex !== -1) {
            const updatedItems = [...this.state.cart];
            updatedItems[existingItemIndex].qty += 1;
            this.setState({cart: updatedItems}, ()=>{
                this.updateSumOfPriceAndQty()
            });
        } else {
            this.setState({cart: [...this.state.cart, {info: item, qty: 1}]}, ()=>{
                this.updateSumOfPriceAndQty()
            })
        }
    }

    clearCartItem = () => {
        this.setState({cart: [], sumPrice: 0, sumQty: 0});
    }

    updateCartItem = (index, operator) => {
        const updatedItems = [...this.state.cart];
        if(operator == "+"){
            updatedItems[index].qty += 1;
        }else{
            if(updatedItems[index].qty <= 1)
                updatedItems.splice(updatedItems[index], 1);            
            else
                updatedItems[index].qty -= 1;
        }
        this.setState({cart: updatedItems}, ()=>{
            this.updateSumOfPriceAndQty()
        });
    };

    updateSumOfPriceAndQty() {
        
        let sumPrice = 0;
        let sumQty = 0;

        this.state.cart.forEach((item) => {
            sumPrice += item.info.price * item.qty;
            sumQty += item.qty;
        });


        this.setState({ sumPrice, sumQty });
    }

    render(){
        return(
            <div className='wraper' id='onPageScroll'>
                <div className='bg-shape bg-shape-left'></div>
            
                
                <div className='menu'>
                    { this.state.load 
                    ? 

                    <BrowserRouter>
                        <Routes>
                            <Route path="/feedback" element={<Feedback Settings={this.state.settings} Language={this.state.language} handleLangaugeSubmit = {this.handleLangaugeSubmit}></Feedback>} />
                            <Route path="/category" element={<Category Settings={this.state.settings} Language={this.state.language} handleLangaugeSubmit = {this.handleLangaugeSubmit} ></Category>} />
                            <Route path="/menu" 
                                                element={<Menu Settings={this.state.settings} 
                                                Language={this.state.language} 
                                                Cart={this.state.cart}
                                                SumPrice={this.state.sumPrice} 
                                                SumQty={this.state.sumQty} 
                                                handleLangaugeSubmit = {this.handleLangaugeSubmit} 
                                                addCartItem = {this.addCartItem} 
                                                clearCartItem = {this.clearCartItem} 
                                                updateCartItem = {this.updateCartItem} ></Menu>} />
                            <Route path="/home" exact element={ <Intro Settings={this.state.settings} Language={this.state.language} ItemId={this.state.ItemId} handleLangaugeSubmit = {this.handleLangaugeSubmit} ></Intro> } />
                            <Route path="/" element={<Navigate replace to="/home" />} />
                            <Route path="*" element={<NotFound></NotFound>} />
                            

                            {/* <Navigate  from="/" to="/home" />
                            <Navigate  to="/notfound" /> */}
                        </Routes>
                    </BrowserRouter>

                    : <Loader></Loader>
                }

                    <div className="footer ani-fade-in" style={ {'fontFamily':'Vidaloka'}}>
                        Developed By <a href='https://www.instagram.com/caviar.menu/' className='caviar'>Caviar Menu</a>
                    </div>
                </div>
                <div className='main-logo'>
                    <img src={Daniklogo} />
                </div>

                <div className='bg-shape bg-shape-right'></div>
                
            </div>
        );
    }

}
