import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import Main from './views/main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Main></Main>
);

