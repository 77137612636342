import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class Category extends Component {

    data = this.props.Data;
    language = this.props.Language;

    
   

    render() {
        return (
            <div data-target={`cat_${this.data.id}`} className={`categories-item ${this.props.Active?'active':''}`} onClick={ () => this.props.handleCategory(this.data.id)}  >
                <img src={this.data.cover} width={50} className='menu-images-cover'/>
                <div style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}} className='menu-t-name'>
                {
                    this.props.Language == "en" ? this.data['name_eng']
                    : this.props.Language == "krd" ? this.data.name_krd
                    : this.data.name_ar
                }
                </div>
            </div>
        );
    }
}

export default Category;