import React, { Component } from 'react'
import ReactStars from "react-rating-stars-component";
import axios from 'axios';
import backArrow from '../assets/backArrow.png';
import { Link, Navigate } from "react-router-dom";

import Daniklogo from '../assets/logo.png';



export default class Feedback extends Component {

    state = {
        staff: 0,
        service: 0,
        clean: 0,
        comment: '',
        contact: '',
        sendFeedback: false,
        redirect: false,
    }

    constructor(props) {
        super(props);
        this.sendFeedback = this.sendFeedback.bind(this);
    }


    handleLanguageChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("feedbackId").style.direction = "rtl";
        else
            document.getElementById("feedbackId").style.direction = "ltr";
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    };

    async sendFeedback() {

        const {data} = await axios.post('https://manage.caviarmenu.com/api/feedback', {
            username: this.props.Settings.username,
            service: this.state.service,
            staff: this.state.staff,
            clean: this.state.clean,
            comment: this.state.comment,
            contact: this.state.contact,
        });
    
        if(data.status)
        {
            this.setState({ sendFeedback: true });

            setTimeout(() => {
                this.setState({ redirect: true });
            }, 1500);
        }
      }
      


  render() {
    return (

        

        


                this.state.redirect ? 
                    <Navigate to="/" replace={true} /> 
                    :
                    <div>
                        <div className='head-feedback ani-ttb-f'>
                            <div className='feedback-head cart-head'>
                                <Link to={"/"}>
                                    <img src={Daniklogo} className='logo' alt="" />
                                </Link>

                                
                                    <div className='cart-head-close'>
                                        <Link to={"/menu"}>
                                            <i className="fa-solid fa-xmark" onClick={()=> this.handleCartClose(false)}></i>
                                        </Link>
                                    </div>
                            </div>

                            <div className='lang'>
                                <div role='button' className={this.props.Language == "en"? 'active': ''} onClick={() => this.handleLanguageChange('en')}> English </div>
                                <div role='button' className={this.props.Language == "ar"? 'active': ''} onClick={() => this.handleLanguageChange('ar')}> العربیة </div>
                                <div role='button' className={this.props.Language == "krd"? 'active': ''} onClick={() => this.handleLanguageChange('krd')}> کوردی </div>
                               
                            </div>
                            
                        </div>
                        {
                            this.state.sendFeedback? 
                                <div className='feedback-success-message'>
                                        {
                                            this.props.Language == "en" ? 'Thank you for your feedback! We appreciate your input and will use it to improve.'
                                            : this.props.Language == "krd" ? 'سوپاس بۆ بۆچوون و تێبینیێن هەوە، بلند دنرخینین و ژ بۆ باشتربوون و پێشڤەچوونێ دێ بکارهینین.'
                                            : 'نشكرك على ملاحظاتك! نقدر مشاركتك وسنستخدمها لتحسين الأمور.'
                                        }
                                </div>
                            :
                                <div className='feedback' id='feedbackId'>
                                    
                                   
                                    
                                    
                                    <div className='ani-fade-in'>
                                    <div className='feedback-item'>
                                    <div className='title'
                                         style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}}>
                                            {
                                                this.props.Language == "en" ? 'Staff Rating:' 
                                                : this.props.Language == "krd" ? 'هەڵسەنگاندنا ستافی'
                                                : 'تقييم الموظفين:'
                                            }
                                        </div>
                                        <div className='rating'>
                                            <div>
                                            <ReactStars
                                                count={5}
                                                onChange={(val) => this.setState({staff:val}) }
                                                size={40}
                                                activeColor="#FBB324"
                                            />
                                            </div>
                                            <div>
                                            <p 
                                                className={`rating-text ${this.props.Language === "en" ? 'exo-font' : ''}`}
                                                >
                                                {
                                                    this.props.Language === "en" ? `${this.state.staff}/5 STARS` 
                                                    : this.props.Language === "krd" ? `${this.state.staff}/5 ستێر`
                                                    : `${this.state.staff}/5 نجوم`
                                                }
                                            </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='feedback-item'>
                                        <div className='title'
                                         style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}}>
                                            {
                                                this.props.Language == "en" ? 'Service Rating:' 
                                                : this.props.Language == "krd" ? 'هەڵسەنگاندنا خزمەتگۆزاریێ'
                                                : 'تقییم الخدمة:'
                                            }
                                        </div>
                                        <div className='rating'>
                                            <div>
                                            <ReactStars
                                                count={5}
                                                onChange={(val) => this.setState({service:val}) }
                                                size={40}
                                                activeColor="#FBB324"
                                            />
                                            </div>
                                            <div>
                                            <p 
                                                className={`rating-text ${this.props.Language === "en" ? 'exo-font' : ''}`}
                                                >
                                                {
                                                    this.props.Language === "en" ?  `${this.state.service}/5 STARS`
                                                    : this.props.Language === "krd" ? `${this.state.service}/5 ستێر`
                                                    :  `${this.state.service}/5 نجوم`
                                                }
                                            </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='feedback-item'>
                                    <div className='title'
                                         style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}}>
                                            {
                                                this.props.Language == "en" ? 'Clean Rating:'
                                                : this.props.Language == "krd" ? 'هەڵسەنگاندنا پاقژیێ'
                                                : 'تقییم النظافة:'
                                            }
                                            
                                        </div>
                                        <div className='rating'>
                                            <div>
                                            <ReactStars
                                                count={5}
                                                onChange={(val) => this.setState({clean:val}) }
                                                size={40}
                                                activeColor="#FBB324"
                                            />
                                            </div>
                                            <div>
                                            <p 
                                                className={`rating-text ${this.props.Language === "en" ? 'exo-font' : ''}`}
                                                >
                                                {
                                                    this.props.Language === "en" ? `${this.state.clean}/5 STARS`
                                                    : this.props.Language === "krd" ? `${this.state.clean}/5 ستێر`
                                                    : `${this.state.clean}/5 نجوم`
                                                }
                                            </p>

                                            </div>
                                        </div>
                                    </div>
                                        <div className='feedback-item'>
                                            <div 
                                                
                                                className={`title ${this.props.Language === "en" ? 'exo-font' : ''}`}
                                                >

                                                    {
                                                        this.props.Language == "en" ? 'Additional Feedback'
                                                        : this.props.Language == "krd" ? 'تێبینی یان پێشنیار'
                                                        : 'ملاحظات إضافية'
                                                    }
                                            </div>
                                            <div className='text-fed'
                                                style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}}>

    {
                                                                this.props.Language == "en" ? 'What else would you like us to know?'
                                                                : this.props.Language == "krd" ? 'تێبینی یان پێشنیارەکا دی تە هەیە؟'
                                                                : 'ما الذي تود إخبارنا به أيضًا؟'
                                                            } 
                                            </div>
                                            <div className='Comment'>
                                                <textarea name='comment' placeholder= {
                                                                this.props.Language == "en" ? 'My feedback!'
                                                                : this.props.Language == "krd" ? 'تێبینیا من!'
                                                                : 'ملاحظتي!'
                                                            } 
                                                onChange={this.handleChange}></textarea>
                                            </div>
                                        </div>

                                        

                                        <div className='feedback-item'>
                                            <div className='text-fed-e'
                                                style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}}>
                                                

                                                {
                                                    this.props.Language == "en" ? 'Phone / Email (optional)'
                                                    : this.props.Language == "krd" ? 'ژمارا تلوفوونێ / ئێمێل (ئەگەر پێدڤیبکەت)'
                                                    : 'رقم الهاتف / البريد الإلكتروني(اختياري)'
                                                }

                                            </div>
                                            <div className='field'>
                                                <input type="text" name='contact' placeholder= {
                                                                    this.props.Language == "en" ? 'Phone / Email:'
                                                                    : this.props.Language == "krd" ? 'ژمارا تلوفوونێ / ئێمێل:'
                                                                    : 'رقم الهاتف / البريد الإلكتروني:'
                                                                } 
                                                                onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className='feedback-item'>
                                            <button onClick={ this.sendFeedback }>

                                            {
                                                this.props.Language == "en" ? 'Send'
                                                : this.props.Language == "krd" ? 'هنارتن'
                                                : 'إرسال'
                                            }

                                                
                                                </button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
    )
  }
}
