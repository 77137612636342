import React, { Component } from 'react';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';

class Subcategory extends Component {

    data = this.props.Data;
    language = this.props.Language;


    render() {
        
        return (
            <div data-target={`cat_${this.data.id}`} className={`categories-item2 ${this.props.Active?'active2':''}`} onClick={ () => this.props.handleSubCategory(this.data.id)} style={this.props.Language === "en" ? { fontFamily: 'Vidaloka' } : {}} >
                {
                    this.props.Language == "en" ? this.data['name_eng']
                    : this.props.Language == "krd" ? this.data.name_krd
                    : this.data.name_ar
                }
            </div>
        );
    }
}

export default Subcategory;