import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import axios from 'axios';
import Item from './item';
import Loader from './../general/loader';
import backArrow from '../../assets/backArrow.png'

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false,
        showLangSelect: false,
    }

    async componentDidMount() {
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/hashtag");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });
    }

    handleChange = e => {
        this.props.handleLangaugeSubmit(e);
    }

    handleShowLangSelect = e => {
        this.setState({showLangSelect: e});
    }

    headCover = {'backgroundImage':`url('${this.settings.introCover}')`, 'backgroundSize':'cover', 'backgroundPosition':'center'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center'}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head" style={this.headCover}>
                            
                            <div className="language">
                                <div 
                                        className='language-active' 
                                        // style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}
                                        onClick={ () => this.handleShowLangSelect(true) }>
                                            <i className="fa-solid fa-globe"></i>
                                            {
                                                this.props.Language == "en"? 'Eng'
                                                : this.props.Language == "krd"? 'Ku'
                                                : 'Ar'
                                            }
                                            <i className="fa-solid fa-chevron-down"></i>
                                </div>
                                
                            </div>

                        <img src={this.settings.logo} alt="" />
                    </div>
                    
                    <div className="category-list">
                        
                        {
                            this.state.load 
                            ?
                                this.state.categories.map( (category, index) => <Item key={index} Data = {category} Language = {this.props.Language} Index={index} Length={this.state.categories.length} ></Item>)
                            : 
                                <Loader></Loader>
                        }

                    </div>

                </div>


                <div 
                        className={`language-wrapper ${this.state.showLangSelect? '': 'd-none'}`} 
                        onClick={ () => this.handleShowLangSelect(false)} >
                    <div className={`language-select`}>
                        <div className='language-select-head'><span>Language</span> <i className="fa-solid fa-angle-up"></i></div>
                        <ul>
                            <li className={`${this.props.Language === "krd"? 'language-select-active': ''}`} onClick={() => this.handleChange('krd')}>کوردی</li>
                            <li className={`${this.props.Language === "ar"? 'language-select-active': ''}`} onClick={() => this.handleChange('ar')}>العربیة</li>
                            <li className={`${this.props.Language === "en"? 'language-select-active': ''}`} style={{'textAlign': 'left'}} onClick={() => this.handleChange('en')}>English</li>
                        </ul>
                    </div>
                </div>
                
            </>
        );
    }
}

export default Category;