import React, { useEffect, useState } from "react";
import spinnerLoader from '../../assets/spiinerLoading.gif';

export default function SPLoader() {
  const [text, setText] = useState('');
  const [showImg, setShowImg] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setShowImg(false);
        setText('3 seconds are done');
      }, 400); // Adjust this to match the duration of the CSS transition
    }, 2500);
  }, []);

  return (
    <>
      <div className='fade-out'>
        {showImg && (
          <div className={`loading-container01 ${fadeOut ? 'fade-out' : ''}`}>
            <span className="loader01"></span>
          </div>
        )}
      </div>
    </>
  );
}
