import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import gsap from 'gsap';
import bgStarsShape from '../../assets/stars-r.png';
import bgStarsShape2 from '../../assets/stars-l.png';
import Daniklogo from '../../assets/logo.png'



class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false
    }

    componentDidMount() {
        // gsap.fromTo("form button", { opacity: 0, y:50 }, { opacity: 1, y:0, duration: 1 });
        // gsap.fromTo(".head img", { opacity: 0, y:-50 }, { opacity: 1, y:0, duration: 1 });

        // gsap.from(".social a", {
        //     rotation: 360,
        //     duration: 5,
        //     ease: "elastic",
        //   });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }


    
 

    render() {
        return (
            <>
                { this.state.navigate && <Navigate to="/menu" replace={true} /> }


                <div className='intro'  style={this.headCover}>
                    <div style={{ position: 'fixed', width: '100%', height: '100vh', overflow: 'hidden' }}>
                    <video 
                        autoPlay 
                        loop 
                        muted 
                         playsInline
                        style={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover', 
                        zIndex: -1 ,
                        }}
                    >
                        <source src={this.settings.introCover} type="video/mp4" />
                    </video>
                    
                    </div>

                    {/* <div className='bg-shape-stars bg-shape-stars-right'><img className='bg-stars-image1' src={bgStarsShape} alt="" /></div> */}
                    
                    <div className="head">
                        <img className='ani-ttb-f' src={Daniklogo} alt="" style={{ width: '173px' }}/>
                    </div>
                    <form onSubmit={this.handleSubmit} >
                        <button className='ani-ltr-f' type='submit' onClick={() => this.handleChange('en')} style={ {fontFamily:'Vidaloka', fontWeight: '400' }} > English </button>    
                        <button className='ani-rtl-f' type='submit' onClick={() => this.handleChange('krd')}> کوردی </button>
                        <button className='ani-ltr-f' type='submit' onClick={() => this.handleChange('ar')}> العربیة </button>
                        
                    </form>

                    <div className="social ani-btt-f">

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }
                         { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook"></i></a>
                        }

                    </div>

                    {/* <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <i class="fa-solid fa-message"></i> Feedback 
                        </Link>
                    </div> */}


                    {/* <div className='bg-shape-stars bg-shape-stars-left'><img className='bg-stars-image' src={bgStarsShape2} alt="" /></div> */}

                    

                </div>
            </>
        );
    }
}

export default Intro;